<template>
  <v-snackbar color="#ffffff" :model-value="SnackBarComponent.SnackbarVmodel" elevation="24" location="top" :timeout="SnackBarComponent.timeout">
    <div class="text-center" :class="`text-${SnackBarComponent.SnackbarColor}`">
      <v-icon size="small" location="start" :color="SnackBarComponent.SnackbarColor">{{
        SnackBarComponent.SnackbarColor == "green" ? "mdi-checkbox-marked-circle" : SnackBarComponent.SnackbarColor == "red" ? "mdi-close-circle" : ""
      }}</v-icon
      >{{ SnackBarComponent.SnackbarText }}
    </div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
};
</script>
