import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";


loadFonts();

Amplify.configure({
  Auth: {
        userPoolId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
        region: process.env.VUE_APP_AWS_REGION,
        userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      },
      API: {
        aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
        aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_GRAPHQL_ENDPOINT,
        aws_appsync_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
        aws_appsync_authenticationType: process.env.VUE_APP_AWS_AUTHENTICATION_TYPE,
      },
});
createApp(App).use(store).use(router).use(vuetify).use(VueApexCharts).mount("#app");
