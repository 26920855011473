<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<style>
.text-color {
  color: var(--v-theme-textColor);
}

.vdataTablescroll .v-data-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #e6e6e6;
}

.vdataTablescroll ::-webkit-scrollbar-thumb {
  background: #b0b0b0 !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}

.cardGradient {
  background-image: linear-gradient(to right, #55acd5, #86c4e1) !important;
}

.cardGradient1 {
  background-image: linear-gradient(to right, #15a9ef, #86c4e1) !important;
}

.cardBorderColor {
  border: 1.5px solid rgb(204, 202, 202) !important;
  background: none !important;
}

.PrimaryFontColor {
  color: #424242;
  font-size: 20px;
  font-weight: bold;
}

.FontSize {
  font-size: 12px !important;
}

.fontSizeSmall {
  font-size: 10px !important;
}

.fontSize14px {
  font-size: 14px !important;
}

.fontSize25px {
  font-size: 25px !important;
}

.fontSize16px {
  font-size: 16px !important;
}

.fontSize35px {
  font-size: 35px !important;
}

.fontSize55px {
  font-size: 55px !important;
}

.maxWidthSmall {
  max-width: 180px !important;
}

.maxWidthXSmall {
  max-width: 150px !important;
}

.maxWidthXXSmall {
  max-width: 120px !important;
}

.maxWidthXXXSmall {
  max-width: 100px !important;
}

.maxWidthSelect {
  max-width: 250px !important;
}

.maxWidthSearch {
  max-width: 250px !important;
}

.borderVariant1 {
  border: 2px solid #0f99da !important;
}

.borderVariant2 {
  border: 1px solid #000000 !important;
}

.v-input .v-label {
  font-size: 12px;
  border: 2px;
}


.field_height .v-field {
  min-height: 12px !important;
  /*  padding: 0 !important;
    max-height: 30px !important */
  display: flex !important;
  align-items: center !important;
}
.v-field__input {
  font-size: 12px !important;
}

.field_label_size .v-input .v-label {
  font-size: 10px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 14px 0 !important;
}

.hover_item:hover {
  background-color: #FFDDC1;
}

.image-upload-input {
  display: none;
  z-index: -9999;
}

.greyColorVariantOne {
  background-color: #dad8d8 !important;
}

.redColorVariantOne {
  background-color: #fcbcbc !important;
}

.greenColorVariantOne {
  background-color: #c6ffd4 !important;
}

.audioPlayer {
  width: 100% !important;
  height: 50% !important;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

.small-tab {
  padding: 4px 6px !important;
  font-size: 12px !important;
}

.custom-list-group .v-list-group__header {
  height: 24px !important;
  /* Adjust the height value as per your requirement */
}

.custom-list-group .v-list-item {
  height: 24px !important;
  /* Adjust the height value as per your requirement */
}

.cursorPointer {
  cursor: pointer !important;
}

.container {
  min-height: 100vh !important;
  /* Set minimum container height to 100% of viewport height */
  display: flex !important;
  /* Use flexbox to align card vertically */
}

/* .custom-scrollbar {
  overflow-y: auto !important;
  scrollbar-width: 4px !important;
  scrollbar-color: #343f52 #f5f5f5 !important;
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 4px !important;
}

.custom-scrollbar ::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #343f52 !important;
  border-radius: 7px !important;
}

.custom-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #343f52 !important;
} */

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #343f52 !important;
}

/* .custom-scrollbar .v-data-table__wrapper::-webkit-scrollbar {
  width: 10px !important;
  height: 12px !important;
  background-color: #343f52;
  color: red;
}
.custom-scrollbar .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #343f52 !important;
  border: solid 7px #e6e6e6 !important;
  border-radius: 7px !important;
} */

.full-height {
  height: 100vh !important;
}

.full-height-card {
  height: 100% !important;
}

.gradient1 {
  background: hsla(14, 93%, 53%, 1);
  background: linear-gradient(90deg,
      hsla(14, 93%, 53%, 1) 0%,
      hsla(30, 84%, 58%, 1) 100%);
}

.short {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  color: black;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.fontSize {
  font-size: 14px;
}

.v-chip {
  font-size: 12px !important;
}

.tableClass table {
  border-collapse: separate;
  border-spacing: 0 4px !important;
  width: 100%;
  table-layout: auto;
  /* Adjust column widths based on content */
}

.tableClass th {
  height: 35px !important;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px !important;
  background-color: #e5f6f6 !important;
  /* Distinct header background color */
  color: #343434 !important;
  /* Distinct header text color */
  border-bottom: 2px solid #09142c !important;
  text-align: left;
  white-space: wrap;
  /* Prevent text wrapping in header */
}

.tableClass table th:first-child {
  /* border-radius: 4px 0 0 4px; */
  border-left: 1px solid #ccc;
}

/* .tableClass table th:last-child {
  border-radius: 0px 4px 4px 0px;
} */

.tableClass tbody td {
  padding: 8px;
  height: 35px !important;
  font-size: 14px !important;
  border: 1px solid #e0e0e0 !important;
  border-left: none !important;
  color: #333;
  /* white-space: wrap; */
  /* text-wrap: nowrap; */
}

.tableClass tbody td:first-child {
  /* border-radius: 6px 0px 0px 6px; */
  border-left: 1px solid #e0e0e0 !important;
  text-align: left;
  text-wrap: nowrap;
}

.tableClass tbody td:last-child {
  border-radius: 0px 6px 6px 0px;
}

.tableClass tr:nth-child(odd) td {
  /* background-color: #f9f9f9; */
  background-color: #f2f2f2;
}

.tableClass tr:nth-child(even) td {
  /* background-color: #f9e4e4; */
  background-color: #ffffff;
}

.tableClass tr:hover td {
  /* background-color: #818fb4; */
  background-color: #d9eaf7;
}

.tableClass td {
  overflow: auto !important;
  transition: background-color 0.3s ease;
}

.tableClass {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.hoverclass:hover {
  cursor: pointer !important;
}
</style>
