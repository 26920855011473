<template>
  <div class="">
    <v-card class="background">
      <v-container fluid class="d-flex align-center justify-center" fill-height height="100%">
        <Snackbar :SnackBarComponent="SnackBarComponent" />
        <div id="particles-js"></div>
        <v-layout class="d-flex align-center justify-center">
            <v-card width="400px" class="login-card" :color="!$vuetify.theme.dark ? '#f0f9fb' : ''" elevation="0">
              <v-card-text class="py-0">
                <div class="image mt-5" v-if="!$vuetify.theme.dark">
                  <v-img class="" contain :src="logoSrc" alt="Logo"></v-img>
                </div>
                <div class="imageDark mt-5" v-else>
                  <v-img class="" contain :src="logoSrcDark" alt="Logo"></v-img>
                </div>

                <div v-if="currentTitle != 'Change Temporary Password'" class="mt-3 ml-4">
                  <h2 class="">{{ currentTitle.title }}</h2>
                  <p class="mt-2">{{ currentTitle.subTitle }}</p>
                </div>
                <div v-else class="mb-0 pb-0">
                  <h2 class="">
                    {{ currentTitle }}
                  </h2>
                </div>

                <v-window v-model="step" class="">
                  <!-- Sign In -->
                  <v-window-item :value="1">
                    <v-card-text>
                      <v-form ref="form" lazy-validation class="">
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Email</div>
                        <v-text-field
                          variant="outlined"
                          density="compact"
                          color="secondary"
                          placeholder="Enter email"
                          :rules="[
                            (v) => !!v || 'Email ID is required',
                            (v) => /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(v) || 'Invalid email address',
                          ]"
                          v-model.trim="Login.EmailID"
                          aria-label="Email Id"
                          ></v-text-field>
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Password</div>
                        <v-text-field
                          variant="outlined"
                          density="compact"
                          color="secondary"
                          aria-label="password"
                          placeholder="Enter password"
                          :type="isPwd ? 'text' : 'password'"
                          @click:append="isPwd = !isPwd"
                          @keydown.enter="ValidateSignUpForm"
                          required
                          :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[
                            (v) => !!v || 'Password is required',
                            (v) =>
                              /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/.test(v) ||
                              'Password must be at least 8 characters and include at least one digit, one letter, and one special character',
                          ]"
                          v-model.trim="Login.Password"></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="py-0 mt-n8">
                      <v-checkbox
                        density="compact"
                        class="my-0 py-0 ml-1"
                        color="primary"
                        aria-label="remember me"
                        v-model="rememberme"
                        label="Remember Me"
                        :disabled="Login.EmailID === '' && Login.Password === ''" />
                      <v-spacer></v-spacer>
                      <v-btn small text class="mb-4 text-capitalize" color="secondary" aria-label="Forgot password" @click="step = 3"
                        >Forgot Password ?</v-btn
                      >
                    </v-card-actions>
                    <v-card-actions class="pt-0 pb-6">
                      <v-row class="px-2">
                        <v-col cols="12" md="12" lg="12" class="">
                          <v-btn
                            block
                            :loading="loading"
                            variant="flat"
                            class="text-capitalize font-weight-bold btn"
                            color="primary"
                            @click="ValidateSignUpForm()"
                            >Sign In</v-btn
                          >
                        </v-col>

                        <v-col class="mt-n3" cols="12" md="12" lg="12" align="center" justify="center">
                          <div block dark depressed text color="secondary">
                            Don't have an account ?
                            <router-link color="primary" to="SignUp"> Sign Up</router-link>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-window-item>
                  <!-- Change Temporary Password -->
                  <v-window-item :value="2">
                    <v-card-text>
                      <v-form ref="formCP" lazy-validation>
                        <div class="FontSize25px text-left mb-1 font-weight-bold">New password</div>
                        <v-text-field
                          class=""
                          variant="outlined"
                          density="compact"
                          placeholder="Enter New Password"
                          aria-label="Enter new password"
                          :type="isPwdNew ? 'text' : 'password'"
                          @click:append="isPwdNew = !isPwdNew"
                          required
                          :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[
                            (v) => !!v || 'Password is required',
                            (v) =>
                              /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/.test(v) ||
                              'Password must be at least 8 characters and include at least one digit, one letter, and one special character',
                          ]"
                          v-model="Password.NewPassword"></v-text-field>
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Re-enter new password</div>
                        <v-text-field
                          class=""
                          variant="outlined"
                          density="compact"
                          placeholder="Re-enter new password"
                          aria-label="Re-enter new password"
                          :type="isPwdConfirm ? 'text' : 'password'"
                          @click:append="isPwdConfirm = !isPwdConfirm"
                          @keydown.enter="ValidateConfirmPasswordForm"
                          required
                          :append-icon="isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Confirm Password is required', (v) => v == Password.NewPassword || 'Password does not matching ⚠️']"
                          v-model="Password.ConfirmPassword"></v-text-field>
                      </v-form>
                      <v-card-actions class="d-flex align-center flex-column ma-0 pa-0">
                        <v-btn block depressed :loading="loading" class="text-capitalize btn" color="primary" @click="ValidateConfirmPasswordForm()"
                          >Sign in</v-btn
                        >
                      </v-card-actions>
                    </v-card-text>
                  </v-window-item>
                  <!-- Forgot Password ? -->
                  <v-window-item :value="3">
                    <v-card-text>
                      <v-form ref="formOTP">
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Registered email</div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          color="secondary"
                          placeholder="Enter registered email"
                          aria-label="Enter registered email"
                          @keydown.enter="ValidateOTP"
                          v-model.trim="OTPEmail"
                          
                          :rules="[
                            (v) => !!v || 'Email ID is required',
                            (v) => /^[a-z0-9.]+@[a-z0-9]+(?:\.[a-z0-9]+)+$/.test(v) || 'Invalid email address',
                          ]"
                          class="">
                        </v-text-field>
                        <!-- <div class="FontSize text-center">*Please enter the e-mail used for HelloWRK login/registration</div> -->
                      </v-form>
                      <v-card-actions class="d-flex align-center flex-column ma-0 pa-0">
                        <v-btn
                          :loading="loading"
                          aria-label="Send Reset Code button"
                          depressed
                          class="text-capitalize btn"
                          block
                          color="primary"
                          @click="ValidateOTP()"
                          >Send Reset Code</v-btn
                        >
                        <v-btn text color="primary" class="mt-2 text-capitalize" small @click="step = 1">
                          <v-icon left>mdi-chevron-left</v-icon>
                          Back To Sign In</v-btn
                        >
                      </v-card-actions>
                    </v-card-text>
                  </v-window-item>
                  <!-- OTP For password Change -->
                  <v-window-item :value="4">
                    <v-card-text>
                      <v-form ref="formConfirmOTP">
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Reset code</div>
                        <v-text-field
                          color="secondary"
                          density="compact"
                          variant="outlined"
                          aria-label="Enter reset code"
                          placeholder="Enter reset code"
                          v-model="ConfirmOTP"
                          :rules="[(v) => !!v || 'Code is required']"
                          class="mt-2"
                          prepend-inner-icon="mdi-contain-start">
                        </v-text-field>
                        <div class="FontSize25px text-left mb-1 font-weight-bold">New password</div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          color="secondary"
                          aria-label="Enter new password"
                          placeholder="Enter new password"
                          v-model="ConfirmNewPassword"
                          :rules="[
                            (v) => !!v || 'Password is required',
                            (v) =>
                              /^(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{8,}$/.test(v) ||
                              'Password must be at least 8 characters and include at least one digit, one letter, and one special character',
                          ]"
                          class=""
                          :type="isPwdConfirmCode ? 'text' : 'password'"
                          @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                          required
                          :append-icon="isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock">
                        </v-text-field>
                        <div class="FontSize25px text-left mb-1 font-weight-bold">Re-enter new password</div>
                        <v-text-field
                          density="compact"
                          variant="outlined"
                          color="secondary"
                          placeholder="Re-enter new password"
                          aria-label="Re-enter new password"
                          v-model="ReenterNewPassword"
                          :rules="[(v) => !!v || 'Password is required', (v) => v == ConfirmNewPassword || 'Password does not matching ⚠️']"
                          class=""
                          :type="isPwdrenter ? 'text' : 'password'"
                          @click:append="isPwdrenter = !isPwdrenter"
                          required
                          :append-icon="isPwdrenter ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock">
                        </v-text-field>
                      </v-form>
                      <v-card-actions class="d-flex align-center flex-column ma-0 pa-0">
                        <v-btn
                          depressed
                          class="text-capitalize btn"
                          aria-label="Reset password button"
                          block
                          color="primary"
                          @click="ValidateEnteredOTP()"
                          >Reset Password</v-btn
                        >
                        <v-btn text color="primary" class="mt-2 text-capitalize" aria-label="Back to sign in button" small @click="step = 1"
                          ><v-icon left>mdi-chevron-left</v-icon>Back To Sign In</v-btn
                        >
                      </v-card-actions>
                    </v-card-text>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-card>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";

import { GetCurrentUserDetails } from "@/mixins/Extras/GetCurrentUser.js";
import { BackgroundParticles } from "@/mixins/particle.js";
export default {
  components: {
    Snackbar,
  },

  mixins: [GetCurrentUserDetails, BackgroundParticles],
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    height: 0,
    step: 1,
    Login: {
      EmailID: "",
      Password: "",
    },
    isPwd: false,
    loading: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    isPwdNew: false,
    isPwdConfirm: false,
    SnackBarComponent: {},
    logoSrc: require("../../assets/logo-dark-new.png"),
    logoSrcDark: require("../../assets/HelloWRK Hz Bk.png"),
    OTPEmail: "",
    ConfirmOTP: "",
    ConfirmNewPassword: "",
    ReenterNewPassword: "",
    isPwdConfirmCode: false,
    rememberme: false,
    isPwdrenter: false,
    isBackgroundOne: true,

    contents: [
      {
        img: "/CarouselImages/target.svg",
        title: "Smart Lead Feeding",
        description:
          " No more wasting time on cold prospects, AI analyzes data from multiple sources to predict the best high-potential leads for your next call",
      },
      {
        img: "/CarouselImages/bulb.svg",
        title: "Personalized Conversations",
        description:
          "Guides your agents with real-time insights and dynamic prompts, ensuring every interaction is tailored to the individual you're speaking with.",
      },
      {
        img: "/CarouselImages/setting.svg",
        title: "Effortless Optimization",
        description:
          "Analyze campaign performance, agent metrics & lead conversion rates in real-time. Strategize, refine and watch your impact soar.",
      },
      {
        img: "/CarouselImages/bar-chart.svg",
        title: "Boost Productivity",
        description:
          "Automate tedious tasks, streamline workflows, empower your agents to make more calls, close more deals and build stronger relationships.",
      },

      {
        img: "/CarouselImages/workflow.svg",
        title: "Streamline Workflows",
        description:
          "Say goodbye to hold times and frustration. Route calls intelligently, empower self-service options, and ensure every customer reaches the right agent quickly",
      },
      {
        img: "/CarouselImages/bulb.svg",
        title: "Resolve issues faster",
        description:
          "Equip agents with comprehensive case histories and real-time guidance. Empower them to solve problems efficiently and leave customers satisfied.",
      },
      {
        img: "/CarouselImages/settings-3.svg",
        title: "Personalize every interaction",
        description:
          "Analyze customer data and past interactions to deliver personalized service. Anticipate needs, offer proactive solutions, and build trust with customers",
      },
      {
        img: "/CarouselImages/growth.svg",
        title: "Boost Measure and improve",
        description:
          "Gain insights into customer sentiment, resolution times and agent performance. Continuously refine your strategy for a service experience that shines.",
      },
    ],
  }),
  watch: {
    rememberme(val) {
      if (val == true) {
        localStorage.setItem("hellowrkportaluseremail", JSON.stringify(this.Login));
      } else if (val == false) {
        delete localStorage.hellowrkportaluseremail;
      }
    },
    isBackgroundOne(newVal) {
      const element = document.getElementById("particles-js");
      if (newVal) {
        element.style.backgroundImage = "linear-gradient(111.1deg, rgb(0, 40, 70) -4.8%, rgb(255, 115, 115) 82.7%, rgb(255, 175, 123) 97.2%)";
      } else {
        element.style.backgroundImage = "linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)";
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return {
            title: "Welcome to HelloWRK!",
            subTitle: "Please sign-in to your account",
          };
        case 2:
          return {
            title: "Reset Password",
            subTitle: "Please reset temporary password",
          };
        case 3:
          return {
            title: "Forgot Password ?",
            subTitle: "Please enter your email to get reset code",
          };
        case 4:
          return {
            title: "Reset Password",
            subTitle: "Enter code and new password",
          };
        default:
          return "Account created";
      }
    },
    
  },

  mounted() {
    const theme = localStorage.getItem("theme");
    const isDarkTheme = theme === "dark";

    this.$vuetify.theme.dark = isDarkTheme;
    this.isBackgroundOne = isDarkTheme;

    this.height = window.innerHeight;
    document.title = "HelloWRK- Signin";
    this.CheckRememberMe();
    this.initParticles();
  },
  methods: {
    SignUpMethod() {
      this.$router.push("SignUp");
    },
    CheckRememberMe() {
      if (localStorage.getItem("hellowrkportaluseremail") != null) {
        this.Login = JSON.parse(localStorage.getItem("hellowrkportaluseremail"));
        this.rememberme = true;
      }
    },
    ConfirmOTPMethod() {
      Auth.forgotPasswordSubmit(this.OTPEmail.trim(), this.ConfirmOTP.trim(), this.ConfirmNewPassword.trim())
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.step = 1;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please enter registered email",
        };
      }
    },
    SendOTPEmail() {
      Auth.forgotPassword(this.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP has been sent to registered email",
          };
          this.step = 4;
        })
        .catch((err) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    async ConfirmPassword() {
      try {
        this.loading = true;
        const result = await Auth.completeNewPassword(this.user, this.Password.ConfirmPassword)
          .then((user) => {
            return user;
          })
          .catch((e) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: e,
            };
            return e;
          });
        if (result.username) {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Welcome back! You are now logged in",
          };
          this.$refs.formCP.reset();
          this.ActivateMethod();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async SignIn() {
      try {
        this.loading = true;
        this.Login.EmailID = this.Login.EmailID.trim();
        this.user = await Auth.signIn(this.Login.EmailID, this.Login.Password.trim());
        if (this.user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.step = 2;
          this.loading = false;
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
        } else {
          this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Welcome you are now logged in.",
          };
          this.ActivateMethod();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    async ActivateMethod() {
      // await this.GetCurrentUserDetailsMethod();

      this.$store.commit("SET_IS_LOGIN_ATTEMPT", true);
      setTimeout(() => this.$router.push("/LandingPage"), 500);
    },
    ValidateConfirmPasswordForm() {
      if (this.$refs.formCP.validate()) {
        if (this.Password.NewPassword == this.Password.ConfirmPassword) {
          this.ConfirmPassword();
        } else {
          this.$refs.form.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please fill all the fields",
        };
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.form.validate()) {
        this.SignIn();
      } else {
        // this.$router.push("LandingPage");
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter email and password",
        };
      }
      // this.$router.push("/LandingPage");
    },
  },
};
</script>
<style scoped>
.background {
  background-size: cover !important;
  background-position: center !important;
  height: 100vh !important;
  overflow: hidden !important;
}
.image {
  display: flex;
  width: 200px;
  padding-left: 0px;
  margin-left: -35px;
}
.imageDark {
  display: flex;
  width: 150px;
  padding-left: 0px;
  margin-left: 0px;
}
.login-card {
  /* /color: black !important; */

  border-radius: 16px;
  /* background-color: #f0f9fb; */
  backdrop-filter: blur(15px);
  opacity: 0.9;
  height: 460px;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 0.9 !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  bottom: 1px;
}
</style>
