import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VTimePicker } from "vuetify/lib/labs/components.mjs";

import { createVuetify } from "vuetify";

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#f47f40",
    secondary: "#343f52",
    pageBackground: "#efecec",
    redChipColor: "#ffebf9",
    redChipTextColor: "#dc56a6",
    greenChipColor: "#aff6d4",
    greenChipTextColor: "#376260",
    yellowChipColor: "#f8eebf",
    yellowChipTextColor: "#817232",
    purpleChipColor: "#e3e5fd",
    purpleChipTextColor: "#4f58c9",
    blueChipColor: "#c5e0ff",
    blueChipTextColor: "#6292c7",
    darkgreyColor: "#000000",
    textColor: "#000000", // Default text color for light theme
    warning: "#ff9800",
    info: "#2196f3",
    success: "#4caf50",
    error: "#f44336",
    background: "#ffffff", // Default background color for light theme
    cardBgColor: "#f47f40",
    bluetext: "#3f11f7",
  },
};

const vuetify = createVuetify({
  components:{
    VTimePicker
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
});

export default vuetify